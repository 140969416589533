.ant-table-thead {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;

  & > tr > .invoice-table {
    padding: 16px !important;
    /*border-color: #e5e7eb;
    border: 0 !important;*/
  }
  & > tr > th {
    padding: 20px 16px 5px 16px !important;
    color: #9fa2b4 !important;
    font-weight: 500 !important;
    background-color: white !important;
    border-bottom: 1px solid !important;

    &::before {
      width: 0 !important;
    }
  }
}
.ant-table-tbody {
  & > tr > .ant-table-cell {
    color: #02084b;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    border-bottom: 1.5px solid #2c348933;
  }
}

.ant-table-row:hover {
  cursor: pointer;
}

/*.card-container {
  .ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
           }
          .ant-tabs-ink-bar {
            background: transparent;
          }
        }
      }
    }
  }
}*/
iframe {
  width: 100%;
  min-height: 1080px;
}
.card-container {
  .ant-tabs-tab {
    padding: 12px;
  }
  .ant-tabs-tab-active {
    padding: 12px 32px;
    background: #cc9933;
    border-radius: 10px 10px 0px 0px;
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
  .ant-tabs-ink-bar {
    background: transparent;
  }
  .ant-tabs-nav {
    margin: 0;
  }
}
