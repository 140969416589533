.profile-upload {
  &.ant-upload.ant-upload-drag {
    background: #f2f3ff;
    border: 1px solid #8f93c1;
    border-radius: 40px;
    &:hover {
      border-color: 1px solid #8f93c1;
    }
  }
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px;
}
.d-btn {
  &:hover {
    color: #02084b !important;
    opacity: 0.6;
  }
}
.ivry-timer {
  &.ant-progress {
    .ant-progress-circle-gradient {
      .ant-progress-text {
        color: #2c3489;
        font-weight: 700;
      }
    }
  }
}
/*.ant-modal-content {
  border-radius: 10px !important;
}*/

.ivry-spinner > div {
  width: 18px;
  height: 18px;
  background: linear-gradient(180deg, #2c3489 0%, #7b61ff 100%);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.ivry-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.ivry-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    opacity: 0.4;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    opacity: 0.4;
  }
  40% {
    opacity: 1;
  }
}
.isa {
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 8px;
  border-collapse: separate;
  .ant-table-thead {
    & > tr > th {
      border-bottom: none !important;
    }
  }
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.swap-row-input {
  border: 1px solid #2c34894d;
  border-radius: 5px;
}
.ivry-swap-input {
  padding: 9px 11px;
  border-radius: 5px;
  color: #02084b;
  border-radius: 5px 0 0 5px !important;
  border-color: #2c34894d;
  border: 0 !important;
  &:focus {
    box-shadow: none;
    border-color: #2c34894d;
  }
}
.ivry-swap-select {
  .ant-select-selector {
    height: 42px !important;
    border: 0px !important;
    color: #02084b;
    padding: 5px 11px !important;
    padding-left: calc(3rem - 8px) !important;
    box-shadow: none !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
.vertical-line {
  .prefix-icon {
    &::before {
      border-left: 1px solid #2c34894d;
      height: 80%;
      display: block;
      content: "";
    }
  }
}

.ant-form input[type="file"] {
  opacity: 0;
  opacity: 0;
  z-index: 1;
  //height: 70px;
  cursor: pointer;
}
.testImage {
  border: 1.5px dashed #015588;
  height: 70px;
  width: 80px;
  border-radius: 8px;
  position: relative;
}
.add-on {
  .ant-input-group-addon {
    height: 45px;
    border-radius: 5px 0px 0px 5px;
    border-color: #6167a7 !important;
    box-shadow: none;
    background: transparent;
    color: #02084b;
  }
  .ant-input {
    height: 45px;
    border-radius: 0px 5px 0px;
    border-color: #6167a7 !important;
    box-shadow: none !important;
    color: #02084b;
  }
}
.invisible-key {
  input {
    font-size: 20px;
  }
}
