.ivry-font {
  font-family: "Rubik";
}
/*.ivry-shadow{
  box-shadow: 0px 4px 10px rgba(44, 52, 137, 0.1);
}*/

.ant-tabs-tab .ant-tabs-tab-btn {
  color: #9fa2b4;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2c3489;
  font-weight: 500;
}

.ant-btn:hover {
  color: white;
}

/*.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
  margin-top: 1.5rem;
}*/

.hook-event > .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff !important;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #66e2a9 !important;
  color: #66e2a9;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #66e2a9;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #2c3489;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #2c3489;
  border-right-width: 1px;
  z-index: 1;
}

.overlay-dropdown {
  z-index: 2000;
}

.ant-upload.ant-upload-select-picture-card {
  width: 60px;
  height: 60px;
  margin: 0px;
  text-align: center;
  vertical-align: top;
  background-color: #ffffff;
  border: 1px solid #3640a0;
  border-radius: 100px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ivory-settings__shadow {
  box-shadow: 0px 4px 10px rgba(44, 52, 137, 0.1);
}

.ivory-storefront__cancelbutton {
  color: #f50100 !important;
  border: #f50100 solid 2px !important;
}

.ivory-storefront__cancelbutton:hover {
  color: #f50100 !important;
}

.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #ffffff;
  border: 1px dashed #2c3489;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 8px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*input:disabled {
    background-color: #e0e0e0 !important;
}*/

.ant-input-number-handler-wrap {
  display: none;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
  float: right;
}

.policy::after {
  content: "*";
  color: red;
}
.grid-wallet {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
